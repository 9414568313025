/* eslint-disable prettier/prettier */
/* eslint-disable quotes */

const surveyJson = {
  locale: "de",
  title: "IBS Expert 5",
  logoPosition: "right",
  completedHtml: {
    de: '<div class="iframe-container"> <iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/brlvj3e70pA?rel=0"></iframe> </div>',
  },
  completedHtmlOnCondition: [
    {
      expression: "{F1} = 'nein'",
      html: {
        de: "Danke für Ihre Teilnahme.",
      },
    },
    {
      expression: "{F3} = 'ja'",
      html: {
        de: '<h3>Funktionelle Diarrhoe. </h3><div class="iframe-container"> <iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/0Blh-vZ1Urg?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{F6} = 'A'",
      html: {
        de: '<h3>{ibs}{arf}{allergies} </h3><div class="iframe-container"> <iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/AN2mc6jwnbY?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{F6} = 'B'",
      html: {
        de: '<h3>{ibs}{arf}{allergies} </h3><div class="iframe-container"> <iframe width="420" height="315" align="center" src="https://www.youtube.com/embed/vQDuFhALx0s?rel=0"> </iframe>  </div>',
      },
    },
    {
      expression: "{F6} = 'C'",
      html: {
        de: '<h3>{ibs}{arf}{allergies} </h3><div class="iframe-container">  <iframe width="420" height="315" align="center" src="https://www.youtube.com/embed/iHVtZEPwXGw?rel=0"> </iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'HIT Allergy'",
      html: {
        de: '<h3>Histamin{ibs}{allergies} </h3><div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/QHIluZCuyVE?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'SIBO Allergy'",
      html: {
        de: '<h3>Allergische Reaktions auf Nahrungsmittel {ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/7s15vo-ot_8?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'KH Allergy'",
      html: {
        de: '<h3>KH Malassimilation{ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/7s15vo-ot_8?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'NMA Allergy'",
      html: {
        de: '<h3>NMA Allergy{ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/7s15vo-ot_8?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'SIBO Allergy'",
      html: {
        de: '<h3>Bakterielle Fehlbesiedlung {ibs} {allergies}</h3><div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/2C0kXIYxo8c?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'SIBO Allergy'",
      html: {
        de: '<h3>Allergische Reaction auf Nahrungsmittel / SIBO  {ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/5w3WZ7qX7YY?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'Laktose Allergy'",
      html: {
        de: '<h3>Laktose + IBS {ibs} {allergies}</h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/eBEWPxygPBs?rel=0"></iframe></div>',
      },
    },
    {
      expression: "{allergies} contains 'Fruktose Allergy'",
      html: {
        de: '<h3>Fruktose {ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/QMlRjrH9i78?rel=0"></iframe> </div>',
      },
    },
    {
      expression: "{allergies} contains 'Weizen Allergy'",
      html: {
        de: '<h3>Weizen {ibs}{allergies} </h3> <div class="iframe-container"><iframe width="420" height="315" align="center"src="https://www.youtube.com/embed/283mmP_XF6s?rel=0"></iframe></div>',
      },
    },
  ],
  pages: [
    {
      name: "F1page",
      elements: [
        {
          type: "html",
          name: "F1Video",
          html: {
            de: '<div class="iframe-container">\n\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/-qBcvSo46To?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "radiogroup",
          name: "F1",
          title: {
            de: "Wurde bei Ihnen bereits die Diagnose Reizdarmsyndrom gestellt und/oder leiden Sie an unklaren Magen-Darm-Beschwerden, für die, trotz umfänglicher Diagnostik inklusive Magen- und Darmspiegelung, keine Ursache gefunden werden konnte?",
          },
          isRequired: true,
          choices: [
            {
              value: "ja",
              text: {
                de: "A: Ja",
              },
            },
            {
              value: "nein",
              text: {
                de: "B: Nein",
              },
            },
          ],
        },
      ],
      title: {
        de: "Eingangsfragen",
      },
    },
    {
      name: "F2page",
      elements: [
        {
          type: "html",
          name: "F2Video",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/DtBATlq6w5A?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "radiogroup",
          name: "F2",
          title: {
            de: "Leiden Sie unter Magen-Darm-Beschwerden (z.B. einem Blähbauch, Bauchschmerzen, vermehrtem Stuhldrang, breiigem Stuhl, Durchfall), überwiegend nach der Nahrungsaufnahme? Haben sie keine oder nur noch sehr wenige dieser Symptome, wenn Sie nichts essen oder spezifische Lebensmittel wie z.B. Milch oder Obst meiden?",
          },
          isRequired: true,
          choices: [
            {
              value: "ja",
              text: {
                de: "A: Ja",
              },
            },
            {
              value: "nein",
              text: {
                de: "B: Nein, meine Symptome bestehen unverändert oder in reduzierter Form auch wenn ich nichts esse",
              },
            },
          ],
        },
      ],
      visibleIf: "{F1} = 'ja' and {F1} = 'ja'",
    },
    {
      name: "F3page",
      elements: [
        {
          type: "html",
          name: "F3Video",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/KDngdnDHSH4?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "radiogroup",
          name: "F3",
          title: {
            de: "Bereitet Ihnen vor allem breiig-wässriger Durchfall mit mehr als 3 Stuhlentleerungen täglich Probleme, während Bauchschmerzen oder ein Blähbauch eher selten auftreten?",
          },
          isRequired: true,
          choices: [
            {
              value: "ja",
              text: {
                de: "A: Ja, ich leide vor allem an wässrigen Durchfällen",
              },
            },
            {
              value: "nein",
              text: {
                de: "B: Nein",
              },
            },
          ],
        },
      ],
      visibleIf: "{F2} = 'nein' and {F1} = 'ja'",
    },
    {
      name: "F5page",
      elements: [
        {
          type: "html",
          name: "F5Video",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/0haFiwja4Wk?rel=0">\n</iframe> \n</div>\n',
          },
        },
        {
          type: "radiogroup",
          name: "F5",
          visibleIf: "{F3} = 'nein'",
          title: {
            de: "Verschlimmern sich Ihre Beschwerden nach dem Essen?",
          },
          choices: [
            {
              value: "ja",
              text: {
                de: "A: Ja",
              },
            },
            {
              value: "nein",
              text: {
                de: "B: Nein, für gewöhnlich verschlimmern sich meine Beschwerden nicht nach dem Essen",
              },
            },
          ],
        },
      ],
      visible: false,
      visibleIf: "{F3} = 'nein' and {F2} = 'nein' and {F1} = 'ja'",
    },
    {
      name: "I15page",
      elements: [
        {
          type: "html",
          name: "I15",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/H6nk9r-oyn4?rel=0">\n</iframe> \n</div>',
          },
        },
      ],
      visible: false,
      visibleIf: "{F5} = 'ja' and {F1} = 'ja'",
    },
    {
      name: "F6page",
      elements: [
        {
          type: "html",
          name: "F6Video",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed//2gKsp32gLUY?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "radiogroup",
          name: "F6",
          title: {
            de: "Welches Symptom ist bei Ihnen führend?",
          },
          choices: [
            {
              value: "A",
              text: {
                de: "A: Wässrige/breiige Durchfälle mehr als 2-3 mal täglich",
              },
            },
            {
              value: "B",
              text: {
                de: "B: Harter Stuhl, starkes Pressen nötig, träger Darm, unvollständige Entleerung, ggf. Abführmittel nötig",
              },
            },
            {
              value: "C",
              text: {
                de: "C: Kombination aus den oberen beiden",
              },
            },
          ],
        },
      ],
      visible: false,
      visibleIf:
        "{F3} = 'nein' and {F2} = 'nein' and {F5} = 'nein' and {F1} = 'ja'",
    },
    {
      name: "F10page",
      elements: [
        {
          type: "html",
          name: "F10Video",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/CwLG3_MgRog?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "radiogroup",
          name: "F10",
          title: {
            de: "Welches Symptom ist bei Ihnen führend?",
          },
          choices: [
            {
              value: "A",
              text: {
                de: "A: Wässrige/breiige Durchfälle mehr als 2-3 mal täglich",
              },
            },
            {
              value: "B",
              text: {
                de: "B: Harter Stuhl, starkes Pressen nötig, träger Darm, unvollständige Entleerung, ggf. Abführmittel nötig",
              },
            },
            {
              value: "C",
              text: {
                de: "C: Kombination aus den oberen beiden",
              },
            },
          ],
        },
      ],
      visible: false,
      visibleIf:
        "{F3} = 'nein' and {F2} = 'nein' and {F5} = 'ja' and {F1} = 'ja'",
    },
    {
      name: "DiagnosePage",
      elements: [
        {
          type: "html",
          name: "D11",
          visibleIf: "{F10} = 'A'",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/2VeJMasOtHY?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "html",
          name: "D12",
          visibleIf: "{F10} = 'B'",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/ytNFlDLkL8g?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "html",
          name: "D13",
          visibleIf: "{F10} = 'C'",
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/8qiDEgTzkzo?rel=0">\n</iframe> \n</div>',
          },
        },
      ],
      visibleIf: "{F3} = 'nein' and {F2} = 'nein' and {F1} = 'ja'",
    },
    {
      name: "I14page",
      elements: [
        {
          type: "html",
          name: "I14",
          visibleIf: "{F2} = 'ja'",
          html: {
            de: '\n<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/GSKxalsPmvg?rel=0">\n</iframe> \n</div>',
          },
        },
      ],
      visibleIf: "{F2} = 'ja' and {F1} = 'ja'",
    },
    {
      name: "I16page",
      elements: [
        {
          type: "html",
          name: "I16Video",

          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/ZokqmjCC8o4?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "nouislider",
          name: "I16_1",
          title:
            "Typischerweise* 30-60 Minuten, spätestens aber 180 Minuten nach dem Genuss von Stärke oder Zucker-haltigen Lebensmitteln wie z.B. Nudeln, Brot, Süßigkeiten oder auch Gemüse wie  Zwiebeln oder Lauch entwickelt sich ein Blähbauch. Es treten zudem damit verbunden häufig Bauchschmerzen, Blähungen und möglicherweise auch vermehrter Stuhldrang sowie Durchfall in Zusammenhang mit der Nahrungsaufnahme auf.",
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "KH",
          isRequired: true,
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
        },
        {
          type: "nouislider",
          name: "I16_2",
          title: {
            de: "Meist sind die Beschwerden morgens meist gut auszuhalten, nehmen aber im Laufe des Tages ständig zu. Nachts bestehen keine Probleme, es sei denn, Sie essen relativ spät.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "KH",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_3",
          title: {
            de: "Es tritt in direktem Zusammenhang mit der Nahrungsaufnahme  für gewöhnlich eines oder mehrere der Symptome auf:  Hautrötung, Hautjucken, tränende Augen, fließende Nase auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "KH",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_4",
          title: {
            de: "Die Symptome sind erstmals kurz nach der Einnahme von Antibiotika oder einem Magen-Darm-Infekt aufgetreten.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "SIBO",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_5",
          title: {
            de: "Die Symptome treten besonders häufig nach der Aufnahme von Zwiebeln, Knoblauch oder Hülsenfrüchten wie z.B. Kichererbsen, Linsen, Bohnen auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "SIBO",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_7",
          title: {
            de: "Die Beschwerden treten ausschließlich nach der Aufnahme von Weizenmehl Produkten (z.B. Brot, Nudeln, Kekse) auf, so dass nach der Meidung von Weizenmehlprodukten keine Beschwerden mehr bestehen.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "NCWS",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_8",
          title: {
            de: "Die Symptome treten besonders häufig nach der Aufnahme von Milchprodukten, insbesondere Kuhmilch, Joghurt, Frischkäse, Sahne, Eiscreme auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "Laktose",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_9",
          title: {
            de: "Die Symptome treten besonders häufig nach der Aufnahme von Fruchtzucker-haltigen Lebensmitteln wie z.B. Fruchtsäften, Honig und Sirup, Äpfeln, Trauben, Kirschen, Wassermelone auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "Fruktose",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I16_10",
          title: {
            de: "Die Symptome treten besonders häufig unter der Aufnahme von Sorbit-haltigen Lebensmitteln wie z.B. Pfirsichen, Pflaumen, Aprikosen, getrockneten Früchten, Kaugummi, Bonbons und Diätgetränken auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          rangeMax: 10,
          rangeMin: 0,
          category: "Sorbit",
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          isRequired: true,
        },
      ],
      visibleIf: "{F2} = 'ja' or {F5} = 'ja' and {F1} = 'ja'",
      requiredIf: "{F2} = 'ja' or {F5} = 'ja'",
      title: {
        de: "Beschwerdebild Kohlenhydrat-Malassimilation",
      },
    },
    {
      name: "I17page",
      elements: [
        {
          type: "html",
          name: "I17Video",
          title: {
            de: 'Vielen dank für Ihre Antworten und Teilnahme an diesem Fragebogen. Klicken Sie auf "Befund erstellen", um Ihren Befund anzuzeigen.',
          },
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/YZOo0K2CbZQ?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "nouislider",
          name: "I17_1",
          title: {
            de: "Typischerweise treten bei Ihnen bereits während oder wenige Minuten (0– 20 Minuten) nach dem Essen Bauchschmerzen auf, evtl. mit einem Blähbauch und einem starken Stuhldrang bis hin zu explosionsartigem Durchfall. ",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "NMA",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I17_2",
          title: {
            de: "Bestimmte Lebensmittel, wie Erdbeeren, Karotten oder Steinobst (z. B. Pfirsich, Aprikose) führen bereits unter Nahrungsaufnahme zu einem Kribbeln im Mund.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "NMA",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I17_3",
          title: {
            de: "Typischerweise treten zusammen mit der Nahrungsaufnahme eines der folgenden Symptome auf: Hautrötung, Hautjucken, tränende Augen laufende Nase.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "NMA",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I17_5",
          title: {
            de: "Sie leiden an Heuschnupfen oder einer anderen Pollenallergie und Ihre Magen-Darm Beschwerden verschlimmern sich saisonal zeitgleich Ihren übrigen Allergie-Symptomen ",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "NMA",
          isRequired: true,
        },
      ],
      visibleIf: "{F2} = 'ja' or {F5} = 'ja'",
      requiredIf: "{F2} = 'ja' or {F5} = 'ja'",
      title: {
        de: "Beschwerdebild Nahrungsmittelallergien",
      },
    },
    {
      name: "I18page",
      elements: [
        {
          type: "html",
          name: "I18Video",
          title: {
            de: 'Vielen dank für Ihre Antworten und Teilnahme an diesem Fragebogen. Klicken Sie auf "Befund erstellen", um Ihren Befund anzuzeigen.',
          },
          html: {
            de: '<div class="iframe-container">\n <iframe width="420" height="315" align="center"\nsrc="https://www.youtube.com/embed/v-3AD4kVqXs?rel=0">\n</iframe> \n</div>',
          },
        },
        {
          type: "nouislider",
          name: "I18_1",
          title: {
            de: "In variablen Abstand zum Essen („Minuten bis Stunden“) treten Bauchschmerzen auf, evtl. mit einem Blähbauch, einem starken Stuhldrang oder auch explosionsartigem Durchfall auf:",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          isRequired: true,
          category: "HIT",
        },
        {
          type: "nouislider",
          name: "I18_2",
          title: {
            de: "In Zusammenhang mit der Nahrungsaufnahmen tritt plötzlich eine Hautrötung (Flush) mit aufsteigender Hitze, möglicherweise auch Quaddeln auf der Haut auf. Die Hautveränderungen und insbesondere die Hautrötung treten vor allem am Brustkorb, am Hals und im Gesicht auf. ",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "HIT",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I18_3",
          title: {
            de: "Es treten häufig Kopfschmerzen im Zusammenhang mit der Nahrungsaufnahme auf.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "HIT",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I18_4",
          title: {
            de: "Weiterhin besteht eines der folgenden Symptome:  eine laufende Nase, verstopfte Nase, Niesen, Husten, Halsschmerzen oder auch Atembeschwerden.",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "HIT",
          isRequired: true,
        },
        {
          type: "nouislider",
          name: "I18_7",
          title: {
            de: "Die Symptome werden sicher durch eines der folgenden Produkte hervorgerufen: reifer Käse, Wein, dunkle Schokolade, Fischkonserven, Essig, Wurstware",
          },
          description: {
            de: "Auf einer Skala von 0 bis 10, spiegelt die Beschreibung Ihre Symptome wider? 0 bedeutet gar nicht, während 10 bedeutet, dass die Beschreibung äußerst präzise auf Sie zutrifft.",
          },
          tooltips: false,
          pipsDensity: 5,
          step: 0.5,
          rangeMax: 10,
          rangeMin: 0,
          category: "HIT",
          isRequired: true,
        },
      ],
      visibleIf: "{F2} = 'ja' or {F5} = 'ja'",
      requiredIf: "{F2} = 'ja' or {F5} = 'ja'",
      title: {
        de: "Beschwerdebild Pseudoallergien | Histamin-Intoleranz",
      },
    },
    {
      name: "Endpage",
      elements: [
        {
          type: "expression",
          name: "EndExpression",
          title: {
            de: 'Vielen dank für Ihre Antworten und Teilnahme an diesem Fragebogen. Klicken Sie auf "Befund erstellen", um Ihren Befund anzuzeigen.',
          },
        },
      ],
    },
  ],
  triggers: [
    {
      type: "setvalue",
      expression: "{F6} = 'A'",
      setToName: "ibs",
      setValue: "RDS-D: Reizdarmsyndrom mit führendem Durchfall",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F6} = 'B'",
      setToName: "ibs",
      setValue: "RDS-O: Reizdarmsyndrom mit führender Verstopfung +/- Schmerz",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F6} = 'C'",
      setToName: "ibs",
      setValue: "RDS-M: Reizdarmsyndrom mit führenden Blähungen +/- Schmerz",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F10} = 'A'",
      setToName: "ibs",
      setValue: "RDS-D: Reizdarmsyndrom mit führendem Durchfall; und",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F10} = 'B'",
      setToName: "ibs",
      setValue:
        "RDS-O: Reizdarmsyndrom mit führender Verstopfung +/- Schmerz; und",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F10} = 'C'",
      setToName: "ibs",
      setValue:
        "RDS-M: Reizdarmsyndrom mit führenden Blähungen +/- Schmerz; und",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F1} = 'ja'",
      setToName: "arf",
      setValue: " ",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F1} = 'ja'",
      setToName: "ibs",
      setValue: " ",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F3} = 'ja'",
      setToName: "ibs",
      setValue: "Funktionelle Diarrhoe",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F2} = 'ja'",
      setToName: "arf",
      setValue: " Unbekannt.",
      isVariable: true,
    },
    {
      type: "setvalue",
      expression: "{F5} = 'ja'",
      setToName: "arf",
      setValue: " Unbekannt.",
      isVariable: true,
    },
  ],
  showTitle: false,
  showPageTitles: false,
  showQuestionNumbers: "off",
  completeText: {
    de: "Befund erstellen",
  },
};

export default surveyJson;
