import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Home from "./Home";
import "./i18n";
import theme from "./theme";
import About from "./Pages/About";
import Newsletter from "./Pages/Newsletter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  { path: "/survey", element: <App /> },
  { path: "/about", element: <About /> },
  { path: "/newsletter/:resultsIds", element: <Newsletter /> },
  { path: "/newsletter", element: <Newsletter /> },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
