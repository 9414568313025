import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#D7F1EB",
    },
    secondary: {
      main: "#0c4843",
    },
    background: {
      default: "#f3fffc",
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 10,
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#0c4843",
              color: "#fff",
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              color: "#0c4843",
              border: "1px solid #0c4843",
            }),
        }),
      },
    },
  },
});

export default theme;
