// @todo add all possible diagnoses
import Types from "./Types";

const diagnoseData = {
  notFound: {
    id: 0,
    name: "no found",
    title: { de: "Leider konnten wir keine Diagnose erstellen" },
    videoUrl: "https://www.youtube.com/embed/brlvj3e70pA",
    type: null,
  },
  hit: {
    id: 1,
    title: { de: "Histaminintoleranz" },
    videoUrl: "https://www.youtube.com/embed/QHIluZCuyVE",
    type: Types.hit,
  },
  functionalDiarrhea: {
    id: 2,
    title: { de: "Funktionelle Diarrhoe" },
    videoUrl: "https://www.youtube.com/embed/0Blh-vZ1Urg",
    type: Types.ibs,
  },
  nma: {
    id: 3,
    title: { de: "Nahrungsmittelallergie" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  nmaSibo: {
    id: 4,
    title: {
      de: "Nahrungsmittelallergie & bakterielle Fehlbesiedlung des Dünndarms",
    },
    videoUrl: "https://www.youtube.com/embed/5w3WZ7qX7YY",
    type: Types.allergy,
  },
  lactose: {
    id: 5,
    title: { de: "Laktoseintoleranz" },
    videoUrl: "https://www.youtube.com/embed/eBEWPxygPBs",
    type: Types.malassimilation,
  },
  fructose: {
    id: 6,
    title: { de: "Fruktoseintoleranz" },
    videoUrl: "https://www.youtube.com/embed/QMlRjrH9i78",
    type: Types.malassimilation,
  },
  sorbit: {
    id: 7,
    title: { de: "Sorbitintoleranz" },
    videoUrl: "https://www.youtube.com/embed/4syrLEPn8es",
    type: Types.malassimilation,
  },
  ncws: {
    id: 8,
    title: { de: "Nicht-Zöliakie-Glutensensitivität" },
    videoUrl: "https://www.youtube.com/embed/283mmP_XF6s",
    type: Types.malassimilation,
  },
  sibo: {
    id: 9,
    title: { de: "Bakterielle Fehlbesiedlung des Dünndarms" },
    videoUrl: "https://www.youtube.com/embed/2C0kXIYxo8c",
    type: Types.malassimilation,
  },
  kh: {
    id: 10,
    title: { de: "Kohlenhydrat-Malassimilation" },
    videoUrl: "https://www.youtube.com/embed/2C0kXIYxo8c",
    type: Types.malassimilation,
  },
  nuts: {
    id: 11,
    title: { de: "Lebensmittelallergie: Nüsse" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  wheat: {
    id: 12,
    title: { de: "Lebensmittelallergie: Weizen" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  chickenProtein: {
    id: 13,
    title: { de: "Lebensmittelallergie: Hühnereiweiß" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  milk: {
    id: 14,
    title: { de: "Lebensmittelallergie: Milch" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  yeast: {
    id: 15,
    title: { de: "Lebensmittelallergie: Hefe" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8",
    type: Types.allergy,
  },
  soya: {
    id: 16,
    title: { de: "Lebensmittelallergie: Soja" },
    videoUrl: "https://www.youtube.com/embed/7s15vo-ot_8", // anpassen
    type: Types.allergy,
  },
  rds_d: {
    id: 17,
    name: "RDS_D",
    title: { de: "Reizdarmsyndrom mit führendem Durchfall" },
    videoUrl: "https://www.youtube.com/embed/AN2mc6jwnbY",
    type: Types.ibs,
  },
  rds_o: {
    id: 18,
    name: "RDS-O",
    title: {
      de: "Reizdarmsyndrom mit führender Verstopfung",
    },
    videoUrl: "https://www.youtube.com/embed/vQDuFhALx0s",
    type: Types.ibs,
  },
  rds_m: {
    id: 19,
    name: "RDS-M",
    title: { de: "Reizdarmsyndrom mit führenden Blähungen" },
    videoUrl: "https://www.youtube.com/embed/iHVtZEPwXGw",
    type: Types.ibs,
  },
  cancel: {
    id: 20,
    name: "cancel",
    title: {
      de: "Dieser Fragebogen richtet sich an Betroffene bei denen bereits die Diagnose Reizdarmsyndrom gestellt wurde und/ oder die  an unklaren Magen-Darm-Beschwerden, für die, trotz umfänglicher Diagnostik inklusive Magen- und Darmspiegelung, keine Ursache gefunden werden konnte. Entsprechend Ihrer Angabe liegt dies nicht vor. Daher ist der vorliegende Fragebogen für Sie nicht geeignet und sollte entsprechend auch nicht weiter von Ihnen bearbeitet werden.",
    },
    videoUrl: null,
    type: null,
  },
  nmaSorbit: {
    id: 21,
    title: { de: "Nahrungsmittelallergie & Sorbitintoleranz" },
    description: {
      de:
        "Entsprechend ihrer Angaben sind wir uns in Ihrem Fall nicht sicher. Teile Ihrer Antworten sprechen für das Vorliegen einer Kohlenhydratverwertungsstörung im Sinne einer Sorbitintoleranz. Wiederum andere Antworten sprechen für das Vorliegen einer allergisch vermittelten Reaktion auf Nahrung.\n" +
        "Dieses Ergebnis ist nicht ungewöhnlich und könnte dafür sprechen, dass beides, also eine Sorbitintoleranz und eine allergisch vermittelte Nahrungsmittelintoleranz bei Ihnen vorliegen.\n" +
        "Wichtig ist es daher beide Möglichkeiten im Rahmen der Therapie zu berücksichtigen.\n" +
        "Weitere Informationen inklusive von Expertenvideos und Zugang zu Schulungsprogrammen rund um das Thema erhalten Sie unter IBS-Expert.de.\n",
    },
    videoUrl: "https://www.youtube.com/embed/5w3WZ7qX7YY",
    type: null,
  },
  nmaFructose: {
    id: 22,
    title: { de: "Nahrungsmittelallergie & Fruktoseintoleranz" },
    description: {
      de:
        "Entsprechend ihrer Angaben sind wir uns in Ihrem Fall nicht sicher. Teile Ihrer Antworten sprechen für das Vorliegen einer Kohlenhydratverwertungsstörung im Sinne einer Fruktoseintoleranz. Wiederum andere Antworten sprechen für das Vorliegen einer allergisch vermittelten Reaktion auf Nahrung.\n" +
        "Dieses Ergebnis ist nicht ungewöhnlich und könnte dafür sprechen, dass beides, also eine Fruktoseintoleranz und eine allergisch vermittelte Nahrungsmittelintoleranz bei Ihnen vorliegen.\n" +
        "Wichtig ist es daher beide Möglichkeiten im Rahmen der Therapie zu berücksichtigen.\n" +
        "Weitere Informationen inklusive von Expertenvideos und Zugang zu Schulungsprogrammen rund um das Thema erhalten Sie unter IBS-Expert.de.\n",
    },
    videoUrl: "https://www.youtube.com/embed/5w3WZ7qX7YY",
    type: null,
  },
  nmaLactose: {
    id: 23,
    title: { de: "Nahrungsmittelallergie & Laktoseintoleranz" },
    description: {
      de:
        "Entsprechend ihrer Angaben sind wir uns in Ihrem Fall nicht sicher. Teile Ihrer Antworten sprechen für das Vorliegen einer Kohlenhydratverwertungsstörung im Sinne einer Laktoseintoleranz. Wiederum andere Antworten sprechen für das Vorliegen einer allergisch vermittelten Reaktion auf Nahrung.\n" +
        "Dieses Ergebnis ist nicht ungewöhnlich und könnte dafür sprechen, dass beides, also eine Laktoseintoleranz und eine allergisch vermittelte Nahrungsmittelintoleranz bei Ihnen vorliegen.\n" +
        "Wichtig ist es daher beide Möglichkeiten im Rahmen der Therapie zu berücksichtigen.\n" +
        "Weitere Informationen inklusive von Expertenvideos und Zugang zu Schulungsprogrammen rund um das Thema erhalten Sie unter IBS-Expert.de.\n",
    },
    videoUrl: "https://www.youtube.com/embed/5w3WZ7qX7YY",
    type: null,
  },
};

export default diagnoseData;
