import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import ReactPDF from "@react-pdf/renderer";
import loadingAnimation from "../../assets/loading.json";
import theme from "../../theme";
import diagnoseData from "../results";

import MyDocument from "./TestPrint";
import PDFDownloadLink = ReactPDF.PDFDownloadLink;

const CompletePage = (props: any) => {
  const { result, completePageDate } = props;
  const { t } = useTranslation();
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setShowAnimation(false);
    }, 2000);

    return () => clearTimeout(animationTimeout);
  }, []);

  const resultsIds = completePageDate
    .map((item: { id: any }) => item.id)
    .join(", ");
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      padding={4}
    >
      {showAnimation && (
        <Player
          autoplay
          loop
          src={loadingAnimation}
          style={{ height: "300px", width: "300px" }}
        />
      )}

      {!showAnimation && (
        <>
          <Box width="100%" marginBottom={2}>
            <Typography typography={"h2"} fontSize={22} align="center">
              {t("result.title")}
            </Typography>
          </Box>
          {completePageDate && completePageDate.length > 0 ? (
            completePageDate.map((item: any, index: any) => (
              <Box key={index} width="100%" marginBottom={2}>
                <Typography variant="h6" align="center">
                  {item && item.title.de}
                </Typography>
                {item.videoUrl && (
                  <div
                    className="iframe-container"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <iframe
                      width="560"
                      height="315"
                      src={`${item && item.videoUrl}?rel=0`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen={false}
                    />
                  </div>
                )}
                <Typography fontSize={"0.8rem"}>
                  {item && item.description && item.description.de}
                </Typography>
              </Box>
            ))
          ) : (
            <Box width="100%" marginBottom={2}>
              <Typography variant="h6" align="center">
                {diagnoseData.notFound.title.de}
              </Typography>
              <div
                className="iframe-container"
                style={{ width: "100%", textAlign: "center" }}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`${diagnoseData.notFound.videoUrl}?rel=0`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen={false}
                />
              </div>
            </Box>
          )}
          <Box>
            <PDFDownloadLink
              document={
                <MyDocument
                  result={result}
                  completePageDate={completePageDate}
                />
              }
              fileName="ibs-expert-Auswertung.pdf"
            >
              {({ loading }) => (loading ? "Lädt ..." : "Download PDF")}
            </PDFDownloadLink>
          </Box>
          <Box height={20} />
          <Button
            component={RouterLink}
            size={"large"}
            to={`/newsletter/${resultsIds}`} // Include the userId in the URL path
            variant="contained"
          >
            {t("result.expertVideos")}
          </Button>

          <Box paddingY={4} />

          <Box width="100%" marginBottom={2}>
            <Typography typography={"h4"} fontSize={16} align="center">
              {t("result.allQuestions")}
            </Typography>
          </Box>

          {result &&
            result.map((item: any, index: number) => (
              <Box
                borderRadius={3}
                bgcolor={"white"}
                p={2}
                key={index}
                width={"90%"}
                marginBottom={2}
                sx={{
                  boxShadow: "4px 9px 8px 0px rgba(0,0,0,0.10)",
                }}
              >
                <Typography
                  sx={{ color: theme.palette.secondary.main }}
                  fontSize={12}
                >
                  {item && item.title}
                </Typography>
                <hr />
                <Typography fontSize={12}>
                  {item && item.displayValue}
                </Typography>
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

export default CompletePage;
