// @ts-ignore
import { SurveyModel } from "survey";

function calculateCategoryScore(
  survey: SurveyModel,
  data: any[],
  category: string,
) {
  let totalScore = 0;
  data.forEach((item) => {
    const question = survey.getQuestionByValueName(item.name) as any; // 'as any' is optional, but it helps with TypeScript
    let qValue = item.value;

    // console.log("Checking question: ", question); // Add this line

    // Only proceed if the question's category matches
    if (question.category === category) {
      switch (question.name) {
        case "I16_1": // KH MA
          if (qValue < 7) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_2": // KH MA
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_3": // KH MA
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = -2;
          }
          break;
        case "I16_4": //  SIBO
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_5": // SIBO
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_7": // NCWS
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_8": // Laktose
          if (qValue <= 5.5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_9": // Fruktose
          if (qValue <= 5.5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I16_10": // Sorbit
          if (qValue <= 5.5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I17_1":
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 2;
          }
          break;
        case "I17_2":
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I17_3":
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 2;
          }
          break;
        case "I17_5":
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;

        case "I18_1":
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I18_2":
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 2;
          }
          break;
        case "I18_3":
          if (qValue <= 5) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I18_4":
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 1;
          }
          break;
        case "I18_7":
          if (qValue <= 7) {
            qValue = 0;
          } else {
            qValue = 2;
          }
          break;
        default:
          // Default logic if you have any
          break;
      }
      totalScore += Number(qValue); // qValue is the user's score (1 to 10)
    }
  });
  return totalScore;
}

export default calculateCategoryScore;
