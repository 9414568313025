const Logo = () => {
  return (
    <svg
      width="161"
      height="62"
      viewBox="0 0 161 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.3203 4.39844C74.3203 4.89323 74.2227 5.35547 74.0273 5.78516C73.8451 6.21484 73.5911 6.59245 73.2656 6.91797C72.9401 7.23047 72.556 7.48438 72.1133 7.67969C71.6836 7.86198 71.2214 7.95312 70.7266 7.95312C70.2318 7.95312 69.763 7.86198 69.3203 7.67969C68.8906 7.48438 68.513 7.23047 68.1875 6.91797C67.875 6.59245 67.6211 6.21484 67.4258 5.78516C67.2435 5.35547 67.1523 4.89323 67.1523 4.39844C67.1523 3.91667 67.2435 3.46094 67.4258 3.03125C67.6211 2.58854 67.875 2.21094 68.1875 1.89844C68.513 1.57292 68.8906 1.31901 69.3203 1.13672C69.763 0.941406 70.2318 0.84375 70.7266 0.84375C71.2214 0.84375 71.6836 0.941406 72.1133 1.13672C72.556 1.31901 72.9401 1.57292 73.2656 1.89844C73.5911 2.21094 73.8451 2.58854 74.0273 3.03125C74.2227 3.46094 74.3203 3.91667 74.3203 4.39844ZM73.4023 31H68.0312V10.082H73.4023V31Z"
        fill="#104034"
      />
      <path
        d="M98.4219 20.3555C98.4219 21.9961 98.1419 23.4935 97.582 24.8477C97.0221 26.2018 96.2604 27.3672 95.2969 28.3438C94.3333 29.3073 93.2005 30.056 91.8984 30.5898C90.6094 31.1237 89.2227 31.3906 87.7383 31.3906C86.2669 31.3906 84.8802 31.1172 83.5781 30.5703C82.2891 30.0234 81.1562 29.2617 80.1797 28.2852C79.2161 27.3086 78.4544 26.1497 77.8945 24.8086C77.3346 23.4544 77.0547 21.9701 77.0547 20.3555V1.76172H82.4062V11.5273C82.6927 11.1628 83.0378 10.8372 83.4414 10.5508C83.8581 10.2643 84.3008 10.0299 84.7695 9.84766C85.2513 9.66536 85.7461 9.52865 86.2539 9.4375C86.7617 9.33333 87.2565 9.28125 87.7383 9.28125C89.2227 9.28125 90.6094 9.5612 91.8984 10.1211C93.2005 10.668 94.3333 11.4362 95.2969 12.4258C96.2604 13.4154 97.0221 14.5872 97.582 15.9414C98.1419 17.2826 98.4219 18.7539 98.4219 20.3555ZM93.0508 20.3555C93.0508 19.5482 92.9076 18.7995 92.6211 18.1094C92.3477 17.4062 91.9701 16.8008 91.4883 16.293C91.0065 15.7852 90.4401 15.388 89.7891 15.1016C89.151 14.8151 88.4674 14.6719 87.7383 14.6719C87.0091 14.6719 86.319 14.8346 85.668 15.1602C85.0299 15.4727 84.4701 15.8958 83.9883 16.4297C83.5065 16.9505 83.1289 17.556 82.8555 18.2461C82.582 18.9232 82.4453 19.6263 82.4453 20.3555C82.4453 21.1628 82.582 21.9115 82.8555 22.6016C83.1289 23.2917 83.5065 23.8906 83.9883 24.3984C84.4701 24.9062 85.0299 25.3099 85.668 25.6094C86.319 25.8958 87.0091 26.0391 87.7383 26.0391C88.4674 26.0391 89.151 25.8958 89.7891 25.6094C90.4401 25.3099 91.0065 24.9062 91.4883 24.3984C91.9701 23.8906 92.3477 23.2917 92.6211 22.6016C92.9076 21.9115 93.0508 21.1628 93.0508 20.3555Z"
        fill="#104034"
      />
      <path
        d="M112.875 31H100.688V25.6875H112.875C113.24 25.6875 113.552 25.5573 113.812 25.2969C114.073 25.0365 114.203 24.724 114.203 24.3594C114.203 23.9818 114.073 23.7344 113.812 23.6172C113.552 23.5 113.24 23.4414 112.875 23.4414H107.328C106.404 23.4414 105.538 23.2656 104.73 22.9141C103.923 22.5625 103.22 22.0872 102.621 21.4883C102.022 20.8763 101.547 20.1667 101.195 19.3594C100.857 18.5521 100.688 17.6862 100.688 16.7617C100.688 15.8372 100.857 14.9714 101.195 14.1641C101.547 13.3568 102.022 12.6536 102.621 12.0547C103.22 11.4557 103.923 10.987 104.73 10.6484C105.538 10.2969 106.404 10.1211 107.328 10.1211H118.129V15.4336H107.328C106.964 15.4336 106.651 15.5638 106.391 15.8242C106.13 16.0846 106 16.3971 106 16.7617C106 17.1393 106.13 17.4648 106.391 17.7383C106.651 17.9987 106.964 18.1289 107.328 18.1289H112.875C113.786 18.1289 114.646 18.2852 115.453 18.5977C116.26 18.8971 116.964 19.3203 117.562 19.8672C118.161 20.4141 118.637 21.0716 118.988 21.8398C119.34 22.6081 119.516 23.4479 119.516 24.3594C119.516 25.2839 119.34 26.1497 118.988 26.957C118.637 27.7513 118.161 28.4544 117.562 29.0664C116.964 29.6654 116.26 30.1406 115.453 30.4922C114.646 30.8307 113.786 31 112.875 31Z"
        fill="#104034"
      />
      <path
        d="M75.51 55.3C73.89 55.3 72.45 54.96 71.19 54.28C69.95 53.58 68.97 52.63 68.25 51.43C67.55 50.23 67.2 48.85 67.2 47.29C67.2 46.05 67.4 44.92 67.8 43.9C68.2 42.88 68.75 42 69.45 41.26C70.17 40.5 71.02 39.92 72 39.52C73 39.1 74.08 38.89 75.24 38.89C76.26 38.89 77.21 39.09 78.09 39.49C78.97 39.87 79.73 40.4 80.37 41.08C81.03 41.76 81.53 42.57 81.87 43.51C82.23 44.43 82.4 45.44 82.38 46.54L82.35 47.86H69.48L68.79 45.4H79.68L79.23 45.91V45.19C79.17 44.53 78.95 43.94 78.57 43.42C78.19 42.9 77.71 42.49 77.13 42.19C76.55 41.89 75.92 41.74 75.24 41.74C74.16 41.74 73.25 41.95 72.51 42.37C71.77 42.77 71.21 43.37 70.83 44.17C70.45 44.95 70.26 45.92 70.26 47.08C70.26 48.18 70.49 49.14 70.95 49.96C71.41 50.76 72.06 51.38 72.9 51.82C73.74 52.26 74.71 52.48 75.81 52.48C76.59 52.48 77.31 52.35 77.97 52.09C78.65 51.83 79.38 51.36 80.16 50.68L81.72 52.87C81.24 53.35 80.65 53.77 79.95 54.13C79.27 54.49 78.54 54.78 77.76 55C77 55.2 76.25 55.3 75.51 55.3Z"
        fill="#104034"
      />
      <path
        d="M96.1202 55L91.0202 48.37L90.5702 47.8L83.9402 39.25H87.8402L92.8502 45.79L93.3602 46.45L99.9002 55H96.1202ZM83.9702 55L90.1802 46.9L92.0102 49.09L87.6302 55H83.9702ZM93.5402 47.38L91.8302 45.22L95.7002 39.25H99.3602L93.5402 47.38Z"
        fill="#104034"
      />
      <path
        d="M102.496 61.6V39.25H105.616V42.85L105.136 42.58C105.256 41.98 105.596 41.41 106.156 40.87C106.716 40.31 107.396 39.86 108.196 39.52C109.016 39.16 109.856 38.98 110.716 38.98C112.136 38.98 113.396 39.33 114.496 40.03C115.596 40.73 116.466 41.69 117.106 42.91C117.746 44.13 118.066 45.53 118.066 47.11C118.066 48.67 117.746 50.07 117.106 51.31C116.486 52.53 115.626 53.5 114.526 54.22C113.426 54.92 112.186 55.27 110.806 55.27C109.886 55.27 108.996 55.09 108.136 54.73C107.276 54.35 106.546 53.87 105.946 53.29C105.346 52.71 104.976 52.11 104.836 51.49L105.616 51.07V61.6H102.496ZM110.296 52.42C111.216 52.42 112.036 52.19 112.756 51.73C113.476 51.27 114.046 50.64 114.466 49.84C114.886 49.04 115.096 48.13 115.096 47.11C115.096 46.09 114.886 45.19 114.466 44.41C114.066 43.61 113.506 42.98 112.786 42.52C112.066 42.06 111.236 41.83 110.296 41.83C109.356 41.83 108.526 42.06 107.806 42.52C107.086 42.96 106.516 43.58 106.096 44.38C105.676 45.18 105.466 46.09 105.466 47.11C105.466 48.13 105.676 49.04 106.096 49.84C106.516 50.64 107.086 51.27 107.806 51.73C108.526 52.19 109.356 52.42 110.296 52.42Z"
        fill="#104034"
      />
      <path
        d="M128.772 55.3C127.152 55.3 125.712 54.96 124.452 54.28C123.212 53.58 122.232 52.63 121.512 51.43C120.812 50.23 120.462 48.85 120.462 47.29C120.462 46.05 120.662 44.92 121.062 43.9C121.462 42.88 122.012 42 122.712 41.26C123.432 40.5 124.282 39.92 125.262 39.52C126.262 39.1 127.342 38.89 128.502 38.89C129.522 38.89 130.472 39.09 131.352 39.49C132.232 39.87 132.992 40.4 133.632 41.08C134.292 41.76 134.792 42.57 135.132 43.51C135.492 44.43 135.662 45.44 135.642 46.54L135.612 47.86H122.742L122.052 45.4H132.942L132.492 45.91V45.19C132.432 44.53 132.212 43.94 131.832 43.42C131.452 42.9 130.972 42.49 130.392 42.19C129.812 41.89 129.182 41.74 128.502 41.74C127.422 41.74 126.512 41.95 125.772 42.37C125.032 42.77 124.472 43.37 124.092 44.17C123.712 44.95 123.522 45.92 123.522 47.08C123.522 48.18 123.752 49.14 124.212 49.96C124.672 50.76 125.322 51.38 126.162 51.82C127.002 52.26 127.972 52.48 129.072 52.48C129.852 52.48 130.572 52.35 131.232 52.09C131.912 51.83 132.642 51.36 133.422 50.68L134.982 52.87C134.502 53.35 133.912 53.77 133.212 54.13C132.532 54.49 131.802 54.78 131.022 55C130.262 55.2 129.512 55.3 128.772 55.3Z"
        fill="#104034"
      />
      <path
        d="M139 55V39.25H142.12V44.2L141.82 43C142.04 42.24 142.41 41.55 142.93 40.93C143.47 40.31 144.08 39.82 144.76 39.46C145.46 39.1 146.18 38.92 146.92 38.92C147.26 38.92 147.58 38.95 147.88 39.01C148.2 39.07 148.45 39.14 148.63 39.22L147.82 42.58C147.58 42.48 147.31 42.4 147.01 42.34C146.73 42.26 146.45 42.22 146.17 42.22C145.63 42.22 145.11 42.33 144.61 42.55C144.13 42.75 143.7 43.04 143.32 43.42C142.96 43.78 142.67 44.21 142.45 44.71C142.23 45.19 142.12 45.72 142.12 46.3V55H139Z"
        fill="#104034"
      />
      <path
        d="M153.635 55V35.23H156.725V55H153.635ZM150.365 42.25V39.25H160.385V42.25H150.365Z"
        fill="#104034"
      />
      <path
        d="M14.25 12.8743H17.0622C16.7816 12.115 16.6324 11.3112 16.6258 10.5007C16.6258 6.5642 19.8142 3.37574 23.7508 3.37574H40.3743C43.7274 3.37351 46.9224 4.78962 49.1739 7.2722C51.425 9.75483 52.5227 13.0748 52.1954 16.4099C51.8948 19.2822 50.5321 21.9406 48.3746 23.862C49.5569 24.4766 50.6279 25.2893 51.5386 26.2623C53.7896 28.7493 54.8895 32.0694 54.5711 35.4091C54.2082 38.386 52.7609 41.1267 50.5032 43.1041C48.2477 45.0813 45.342 46.1567 42.3431 46.1255H38.0013C37.3712 46.1255 36.7655 46.3749 36.3202 46.8202C35.875 47.2656 35.6255 47.8689 35.6255 48.499V53.2505C35.6255 54.562 34.5612 55.624 33.2498 55.624C31.9383 55.624 30.8762 54.562 30.8762 53.2505V48.499C30.8762 44.5647 34.0647 41.374 38.0012 41.374H42.343C46.1104 41.4564 49.3386 38.6977 49.842 34.9615C50.0446 32.9577 49.3877 30.9627 48.0362 29.4686C46.6847 27.9768 44.7654 27.124 42.7504 27.124C42.4498 27.1218 42.1514 27.0617 41.8731 26.9481C41.2452 27.055 40.6106 27.1151 39.9738 27.124H14.2503C12.9388 27.124 11.8745 26.062 11.8745 24.7505C11.8745 23.4391 12.9388 22.3747 14.2503 22.3747H39.967C43.7343 22.4571 46.9626 19.6962 47.4659 15.9622C47.6685 13.9584 47.0117 11.9634 45.6601 10.4693C44.3086 8.9753 42.3893 8.12475 40.3743 8.12475H23.7486H23.7508C22.4393 8.12475 21.375 9.18905 21.375 10.5005C21.375 11.812 22.4393 12.874 23.7508 12.874H40.3743C41.6857 12.874 42.75 13.9383 42.75 15.2498C42.75 16.5613 41.6857 17.6256 40.3743 17.6256H14.25C10.3157 17.6256 7.12502 20.814 7.12502 24.7506C7.12502 28.6849 10.3157 31.8756 14.25 31.8756H42.75C44.0615 31.8756 45.1258 32.9376 45.1258 34.2491C45.1258 35.5606 44.0615 36.6249 42.75 36.6249H33.2493C29.3149 36.6249 26.1243 39.8156 26.1243 43.7499V53.2506C26.1243 54.5621 25.0622 55.6242 23.7507 55.6242C22.4393 55.6242 21.375 54.5621 21.375 53.2506V43.7499C21.3839 41.1715 22.2389 38.6688 23.8108 36.6249H14.2502C10.0086 36.6249 6.08779 34.3604 3.96568 30.6866C1.84374 27.0128 1.84374 22.486 3.96568 18.8124C6.08762 15.1385 10.0087 12.8743 14.25 12.8743Z"
        fill="#104034"
      />
    </svg>
  );
};
export default Logo;
