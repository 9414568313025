enum Category {
  HIT = "HIT",
  Nuts = "Nüsse",
  Wheat = "Weizen",
  ChickenProtein = "Hühnereiweiß",
  Milk = "Milch",
  Yeast = "Hefe",
  Soya = "Soja",
  NMA = "NMA",
  KH = "KH",
  Sorbit = "Sorbit",
  Fructose = "Fruktose",
  Lactose = "Laktose",
  NCWS = "NCWS",
  SIBO = "SIBO",
}

export default Category;
