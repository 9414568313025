import React, { useCallback, useState } from "react";
import "./App.css";
import "survey-core/survey.i18n";
import { Survey } from "survey-react-ui";

import * as SurveyCore from "survey-core";
import { Model, Serializer, StylesManager } from "survey-core";
// @ts-ignore
import { SurveyModel } from "survey";

// @ts-ignore
import { nouislider } from "surveyjs-widgets";
import ImportantNote from "./Pages/ImportantNote";

import "survey-core/defaultV2.min.css";
import "nouislider/dist/nouislider.min.css";

import surveyJson from "./survey/survey";
import calculateCategoryScore from "./utils/calculateScore";
import countMultipleOptions from "./utils/siboOptions";
import Category from "./survey/Categories";
import CompletePage from "./survey/components/CompletePage";
import diagnoseData from "./survey/results";
import alertResults from "./utils/alertResults";

// import sina1 from "./TestDiagnoses/nmaSibo";

nouislider(SurveyCore);
StylesManager.applyTheme("defaultV2");

function App() {
  Serializer.addProperty("question", { name: "category:string" });

  const survey = new Model(surveyJson);
  const [counter, setCounter] = React.useState(0);
  const [result, setResult] = useState<any>(null);
  const [completePageDate, setCompletePageData] = useState<any>(null);
  const memoizedAlertResults = useCallback(alertResults, []);
  let sibo = false;
  // survey.data = sina1;
  survey.onComplete.add((sender: SurveyModel) => {
    memoizedAlertResults(sender);
    const plainData = sender.getPlainData({});
    console.log("plainData", plainData);
    const HitScore = calculateCategoryScore(survey, plainData, Category.HIT);
    const NutsScore = calculateCategoryScore(survey, plainData, Category.Nuts);
    const WheatScore = calculateCategoryScore(
      survey,
      plainData,
      Category.Wheat,
    );
    const ChickenProtein = calculateCategoryScore(
      survey,
      plainData,
      Category.ChickenProtein,
    );
    const MilkScore = calculateCategoryScore(survey, plainData, Category.Milk);
    const YeastScore = calculateCategoryScore(
      survey,
      plainData,
      Category.Yeast,
    );
    const SoyaScore = calculateCategoryScore(survey, plainData, Category.Soya);
    const NMAScore = calculateCategoryScore(survey, plainData, Category.NMA);
    const KHScore = calculateCategoryScore(survey, plainData, Category.KH);
    const SorbitScore = calculateCategoryScore(
      survey,
      plainData,
      Category.Sorbit,
    );

    const FructoseScore = calculateCategoryScore(
      survey,
      plainData,
      Category.Fructose,
    );

    const LactoseScore = calculateCategoryScore(
      survey,
      plainData,
      Category.Lactose,
    );
    const NCWSScore = calculateCategoryScore(survey, plainData, Category.NCWS);
    const SIBOScore = calculateCategoryScore(survey, plainData, Category.SIBO);

    let ibs: any = null;
    let malassimilation: any[] = [];
    const allergies: any[] = [];
    let hit: any = null;
    const ibsV = sender.getVariable("ibs");
    const diagnosedWithIbs = sender.getValue("F1");
    console.log("ibs", ibsV);

    if (diagnosedWithIbs === "nein") {
      ibs = diagnoseData.cancel;
    }
    // if KHScore less than 4, HIT and NMA get raised by two according to IBS Expert script
    /*  if (KHScore < 7.5) {
                                            NMAScore += 2;
                                            HitScore += 2;
                                          } */

    if (ibsV === "Funktionelle Diarrhoe") {
      ibs = diagnoseData.functionalDiarrhea;
    }
    if (
      ibsV === "RDS-D: Reizdarmsyndrom mit führendem Durchfall" ||
      ibsV === "RDS-D: Reizdarmsyndrom mit führendem Durchfall; und"
    ) {
      ibs = diagnoseData.rds_d;
    }
    if (
      ibsV === "RDS-O: Reizdarmsyndrom mit führender Verstopfung +/- Schmerz" ||
      ibsV ===
        "RDS-O: Reizdarmsyndrom mit führender Verstopfung +/- Schmerz; und"
    ) {
      ibs = diagnoseData.rds_o;
    }
    if (
      ibsV === "RDS-M: Reizdarmsyndrom mit führenden Blähungen +/- Schmerz" ||
      ibsV === "RDS-M: Reizdarmsyndrom mit führenden Blähungen +/- Schmerz; und"
    ) {
      ibs = diagnoseData.rds_m;
    }
    if (NMAScore < 2 && HitScore >= 4) {
      hit = diagnoseData.hit;
    }
    if (NMAScore >= 2 && NCWSScore < 1) {
      allergies.push(diagnoseData.nma);
    }
    if (NutsScore >= 1) {
      allergies.push(diagnoseData.nuts);
    }
    if (WheatScore >= 1) {
      allergies.push(diagnoseData.wheat);
    }
    if (ChickenProtein >= 1) {
      allergies.push(diagnoseData.chickenProtein);
    }
    if (MilkScore >= 1) {
      allergies.push(diagnoseData.milk);
    }
    if (YeastScore >= 1) {
      allergies.push(diagnoseData.yeast);
    }
    if (SoyaScore >= 1) {
      allergies.push(diagnoseData.soya);
    }
    if (KHScore >= 1) {
      malassimilation.push(diagnoseData.kh);
      if (SorbitScore >= 1) {
        malassimilation.push(diagnoseData.sorbit);
        if (FructoseScore >= 1 || LactoseScore >= 1) {
          // SIBO
          if (SIBOScore >= 2 && !malassimilation.includes(diagnoseData.sibo)) {
            malassimilation.push(diagnoseData.sibo);
          }
        }
      }
      if (FructoseScore >= 1) {
        malassimilation.push(diagnoseData.fructose);
        if (SorbitScore >= 1 || LactoseScore >= 1) {
          // SIBO
          if (SIBOScore >= 2 && !malassimilation.includes(diagnoseData.sibo)) {
            malassimilation.push(diagnoseData.sibo);
            sibo = true;
          }
        }
      }
      if (LactoseScore >= 1) {
        malassimilation.push(diagnoseData.lactose);
        if (FructoseScore >= 1 || SorbitScore >= 1) {
          // SIBO
          if (SIBOScore >= 2 && !malassimilation.includes(diagnoseData.sibo)) {
            malassimilation.push(diagnoseData.sibo);
          }
        }
      }
      if (NCWSScore >= 1 && SIBOScore < 2 && NMAScore < 2) {
        malassimilation.push(diagnoseData.ncws);
      }
    }

    const combinedResults: any[] = [
      ...(ibs !== null ? [ibs] : []),
      ...malassimilation.filter((item) => item !== null),
      ...allergies.filter((item) => item !== null),
      ...(hit !== null ? [hit] : []),
    ];

    const optionsToCheck = [
      diagnoseData.lactose,
      diagnoseData.fructose,
      diagnoseData.sorbit,
      diagnoseData.ncws,
      diagnoseData.sibo,
      diagnoseData.kh,
    ];
    // If only KH is present in the array, remove it.
    if (
      malassimilation.length === 1 &&
      malassimilation[0] === diagnoseData.kh
    ) {
      malassimilation = []; // or malassimilation.splice(0, 1);
    }
    // Check if more than one of the options is present (KH should always be present if another is), then
    // remove those if sibo applies, at the end append sibo
    const siboCount = countMultipleOptions(malassimilation, optionsToCheck);
    console.log("siboCount", siboCount);
    if (siboCount > 1) {
      // todo siboCount > 2 warum 2?
      console.log("Mehr als eine SIBO-Option, sollte SIBO anzeigen.");
      for (let i = malassimilation.length - 1; i >= 0; i -= 1) {
        if (optionsToCheck.includes(malassimilation[i])) {
          malassimilation.splice(i, 1);
        }
      }
      malassimilation.push(diagnoseData.sibo);
      sibo = true;
    } else {
      console.log("Höchstens eine SIBO-Option, zeigt normal an.");
    }

    const allergyLength = allergies.length;
    const filteredAllergies = () => {
      if (allergyLength >= 1 && !sibo) {
        return [diagnoseData.nma];
      }
      if (
        allergyLength >= 1 &&
        malassimilation.length <= 2 &&
        LactoseScore >= 1
      ) {
        return [diagnoseData.nmaLactose];
      }
      if (
        allergyLength >= 1 &&
        malassimilation.length <= 2 &&
        FructoseScore >= 1
      ) {
        return [diagnoseData.nmaFructose];
      }
      if (
        allergyLength >= 1 &&
        malassimilation.length <= 2 &&
        SorbitScore >= 1
      ) {
        return [diagnoseData.nmaSorbit];
      }

      if (allergyLength >= 1 && sibo) {
        console.log("emtpy the MA array");
        malassimilation = [];
        return [diagnoseData.nmaSibo];
      }
      return [];
    };

    const displayedResults: any = [
      ...(ibs !== null ? [ibs] : []),
      ...malassimilation.filter((item) => {
        console.log("malassimilation items", item);
        if (allergyLength >= 1 && sibo) {
          return null;
        }
        return item !== null;
      }),
      ...filteredAllergies(),
      ...(hit !== null ? [hit] : []),
    ];
    console.log("ibs", ibs);
    console.log("hit", hit);
    console.log("malassimilation", malassimilation);
    console.log("allergyLength", allergies);

    console.log("combinedResults", combinedResults);
    setCompletePageData(displayedResults);
    console.log("displayedResults", displayedResults);
    setResult(plainData);

    setCounter(counter + 1);
  });

  if (counter === 0) {
    // note before survey starts
    return <ImportantNote counter={counter} setCounter={setCounter} />;
  }
  if (counter === 1) {
    return (
      <div>
        <Survey model={survey} />
      </div>
    );
  }
  if (counter === 2) {
    // survey complete page
    return <CompletePage completePageDate={completePageDate} result={result} />;
  }
  return <div />;
}

export default App;
