import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Navigation from "./common/Navigation";
import videoImage from "./assets/undraw_online_learning_re_qw08.svg";

export default function ResponsiveDrawer() {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Navigation />
      <Box
        width={{ md: "70%" }}
        sx={{
          p: 3,
        }}
      >
        <Toolbar />
        <div style={{ height: 20 }} />
        <div className="iframe-container">
          <iframe
            width="420"
            height="315"
            src="https://www.youtube.com/embed/-qBcvSo46To?rel=0"
          ></iframe>
        </div>
        <Typography paddingTop={2} paragraph>
          {t("Home.description")}
        </Typography>
        <Box sx={{ paddingTop: 2, justifyContent: "center", display: "flex" }}>
          <Button
            component={RouterLink}
            size={"large"}
            to={"/survey"}
            variant="contained"
          >
            {t("Home.start")}
          </Button>
        </Box>
        <Box style={{ height: 40 }} />
        <Box
          px={1}
          paddingTop={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={videoImage}
            style={{ width: 170, paddingBottom: 12 }}
            alt="website logo"
          />
          <Typography textAlign={"center"} paddingTop={1} paragraph>
            {t("Home.expertVideos")}
          </Typography>
        </Box>

        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <Button
            component={RouterLink}
            size={"large"}
            to={"/newsletter"}
            variant="outlined"
          >
            {t("Home.expertVideosButton")}
          </Button>
        </Box>
        <div style={{ height: 100 }} />
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            margin: "auto",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <img src={"./images/team.png"} alt={"Team"} loading="lazy" />
          <Typography paragraph sx={{ margin: 4 }}>
            {t("Home.team")}
          </Typography>
        </Box>
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <Button
            component={RouterLink}
            size={"large"}
            to={"/about"}
            variant="outlined"
          >
            {t("Home.more")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
