// @ts-ignore
import { SurveyModel } from "survey";

const alertResults = (sender: SurveyModel) => {
  const surveyresult = JSON.stringify(sender.data);
  console.log(JSON.stringify({ surveyresult }));

  const formdata = new FormData();
  formdata.append("surveyresult", JSON.stringify(surveyresult));

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow" as const,
  };

  fetch("https://meal-advisor.com/ibs/save-survey-results.php", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export default alertResults;
