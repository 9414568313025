import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import de from "./translations/de.json";

// the translations
const resources = {
  de: {
    translation: de,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    fallbackLng: "de", // use en if detected lng is not available
    detection: { lookupFromPathIndex: 0 },
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
