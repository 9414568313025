import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navigation from "../common/Navigation";
import drawerWidth from "../common/variables";

const About = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <Navigation />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <h1>{t("About.title")}</h1>
        <Typography>{t("About.text")}</Typography>
      </Box>
    </Box>
  );
};

export default About;
