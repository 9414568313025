import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { HomeRounded, People, Security } from "@mui/icons-material";

const DrawerComp = () => {
  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HomeRounded />
            </ListItemIcon>
            <Link component={RouterLink} color={"secondary"} to={"/"}>
              <ListItemText primary={"Home"} />
            </Link>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <Link component={RouterLink} color={"secondary"} to={"/about"}>
              <ListItemText primary={"Über uns"} />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText primary={"Impressum"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};
export default DrawerComp;
