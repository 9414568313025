import * as React from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Logo from "../assets/Logo";

const AppBarView = (props: {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}) => {
  const { drawerWidth, handleDrawerToggle } = props;
  return (
    <AppBar
      position="fixed"
      sx={{
        // width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <div style={{ padding: 4, marginTop: 10 }}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, marginLeft: "auto" }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
export default AppBarView;
